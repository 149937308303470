import React, { useRef, useState } from "react";
import "./Contact.scss";
import CustomGoogleMapWithMarker from "./CustomGoogleMapWithMarker";
import denemelogo from "../../assets/contactkabe.jpg";
import convid from "../../assets/kabe.mp4";
import ReCAPTCHA from "react-google-recaptcha";
import EmailIcon from "@mui/icons-material/Email";
import DirectionsIcon from "@mui/icons-material/Directions";
import SmartphoneIcon from "@mui/icons-material/WhatsApp";
import emailjs from "@emailjs/browser";
import contactimg from "../../assets/contactimg.png";
const Contact = () => {
  const toggle = () => setCapcthaDone((prevState) => !prevState);
  const [capcthaIsDone, setCapcthaDone] = useState(false);
  function onChange() {
    toggle();
  }
  const form = useRef();
  let width = window.screen.width;
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ytjpxwn",
        "template_r2qnrbb",
        form.current,
        "cKwdft6KA58Xa-0Nx"
      )
      .then(
        (result) => {
          if (result.status === 200) {
            alert("Mesajınız Gönderildi");
            window.location.reload();
          } else {
            alert("Bir hata oluştu. Lütfen tekrar deneyin.");
            window.location.reload();
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <div id="umre-container">
        <div
          id="umre-img-cont"
          style={{ backgroundImage: `url(${contactimg})` }}
        ></div>
      </div>

      <div id="contact-cont">
        <div id="contact-first">
          {width >= 880 ? (
            <video src={convid} autoPlay muted loop />
          ) : (
            <img src={denemelogo} alt=""></img>
          )}
          <div id="video-blur">
            <p>
            Evliya Turizm ile Manevi Huzura Kavuşun<hr></hr> Kutsal Topraklarda Ruhunuzu Yenileyin!
            </p>
          </div>
        </div>
        <p id="page-titles">BİZE ULAŞIN</p>
        <div id="contact-sec">
          <div id="form-div">
            <img
              style={{ height: "40em", marginLeft: "2em" }}
              src={denemelogo}
              alt="callcenter"
            ></img>

            <form id="form-inside" ref={form} onSubmit={sendEmail}>
              <h2>
                İLETİŞİM
                <hr id="title-hr"></hr>
              </h2>
              <div id="form-name-email">
                <input
                  maxlength={26}
                  minLength={6}
                  placeholder="İsim Soyisim"
                  type="text"
                  for="name"
                  name="name"
                />

                <input
                  maxlength={30}
                  minLength={6}
                  placeholder="Email"
                  type="email"
                  name="email"
                />
              </div>

              <input
                maxlength={40}
                minLength={3}
                placeholder="Telefon Numarası"
                type="phone"
                name="phone"
              />
              <textarea
                placeholder="Mesajınızı Yazınız"
                maxlength={500}
                minLength={6}
                name="message"
                rows={6}
              ></textarea>
              <div id="recaptcha">
                <ReCAPTCHA
                  sitekey="6LdtP-IpAAAAAOKz8MeHHzD-p9Ug-nnEwFTzFuLG"
                  onChange={onChange}
                />
                {capcthaIsDone === true ? (
                  <button style={{ cursor: "pointer",}} type="submit" value="Gönder">
                    Gönder
                  </button>
                ) : (
                  <button
                    style={{
                      backgroundColor: "orange",
                     
                    }}
                    disabled
                    type="submit"
                    value="Gönder"
                  >
                    Gönder
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
        <div id="contact-boxes">
          <a href="mailto:Evliyaturizim@gmail.com" className="boxes">
            <EmailIcon style={{ fontSize: "4em", color: "black" }} />
            <h2 style={{ color: "black", textDecorationLine: "none" }}>
              Mail Gönder
            </h2>
          </a>
          <a
            href="https://maps.app.goo.gl/oJX4XQQkB6HNrCV37"
            target="_blank"
            rel="noreferrer"
            className="boxes"
          >
            <DirectionsIcon style={{ fontSize: "4em", color: "black" }} />
            <h2 style={{ color: "black", textDecorationLine: "none" }}>
              Ziyaret Et
            </h2>
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=+905393815951"
            target="_blank"
            className="boxes"
            rel="noreferrer"
          >
            <SmartphoneIcon style={{ fontSize: "4em", color: "black" }} />
            <h2 style={{ color: "black", textDecorationLine: "none" }}>
            0539 381 59 51
            </h2>
          </a>
        </div>
        <div
          style={{
            width: "100%",
            height: "49vh",
            marginTop: "5em",
            marginBottom: "5em",
          }}
        >
          <CustomGoogleMapWithMarker></CustomGoogleMapWithMarker>
        </div>
      </div>
    </div>
  );
};

export default Contact;
