import React, { useState } from "react";
import "./Navbar.scss";
import logo from "../../assets/evliyalogo.png";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import flag from "../../assets/flag.png";
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen((open) => !open);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: for smooth scrolling
    });
  };

  return (
    <div>
      <div id="nav-top">
        <img src={flag} alt="flag" id="flag"  style={{left:0}}></img>
        <div >HÜSEYİN YILMAZ TURİZM</div>
        <img src={flag}  style={{rotate:'180deg'}} alt="flag" id="flag"></img>
      </div>

      <div id="navbar-container">
        <Link style={{ width: "10em" }} to="/">
          <img id="nav-logo" alt="logo" src={logo}></img>
        </Link>

        <div className={`dvs-header__menuItems ${isOpen ? "is-open" : ""}`}>
          <Link onClick={toggleMenu} className="navlinks" to={"/"}>
            ANA SAYFA
          </Link>
          <Link onClick={toggleMenu} className="navlinks" to={"/umre"}>
            UMRE
          </Link>
          <Link onClick={toggleMenu} className="navlinks" to={"hac"}>
            HAC
          </Link>
          <Link onClick={toggleMenu} className="navlinks" to={"/knowledge"}>
            MEDYA
          </Link>
          <Link onClick={toggleMenu} className="navlinks" to={"/aboutus"}>
            HAKKIMIZDA
          </Link>
          <Link onClick={toggleMenu} className="navlinks" to={"/contact"}>
            İLETİŞİM
          </Link>
        </div>
        <div className="dvs-header__trigger" onClick={toggleMenu}>
          <MenuIcon></MenuIcon>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
