import React from "react";
import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";
const CustomGoogleMapWithMarker = () => {
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const center = {
    lat: 39.942538661570076,
    lng: 32.856894107233266,
  };
  return (
    <LoadScript googleMapsApiKey="AIzaSyDcF8_2i3Tii8VEZQo0cucl8k67ahSq3bY">
      <GoogleMap
        mapContainerStyle={containerStyle}
        Marker
        center={center}
        zoom={15}
      >
        <MarkerF position={{ lat: 39.942538661570076, lng: 32.856894107233266 }} />
       
      </GoogleMap>
    </LoadScript>
  );
};

export default CustomGoogleMapWithMarker;
