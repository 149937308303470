import React from "react";
import "./Footer.scss";
import logo from "../../assets/evliyalogo.png";
import tursab from "../../assets/sertifika.jpg";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import CustomGoogleMapWithMarker from "../contact/CustomGoogleMapWithMarker";
import { Link } from "react-router-dom";
const Footer = () => {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });
  };

  function trackPixel() {
    console.log("Phone number clicked");
  }
  return (
    <div id="footer-cont">
      <div id="footer-first">
        <div className="footer-items">
        <img
          style={{ width: "16em" }}
          alt="footer-logo"
          src={logo}
          
        ></img>
        <p style={{fontWeight:'bolder'}}>HÜSEYİN YILMAZ TURİZM <br></br>TURSAB BELGE NO: 16712</p>
        </div>
      
        <img alt="tursab-logo" src={tursab} className="footer-items"></img>
        <div className="footer-items">
          <div>
            {" "}
            <FmdGoodIcon></FmdGoodIcon>{" "}
            <span style={{ fontWeight: "bold" }}>Merkez:</span>{" "}
            <p>
              Hacıbayram Mah. Hükümet Cad. No: 5/48 <br></br> Kat: 2 (Oğultürk
              İşhanı) Ulus / Ankara
            </p>
          </div>
          <div>
            {" "}
            <AccessAlarmsIcon></AccessAlarmsIcon> Pazartesi - Cumartesi 09:00 -
            18:00
          </div>
          <div>
            {" "}
            <LocalPhoneIcon></LocalPhoneIcon>
            0312 310 91 91<br></br>
            <LocalPhoneIcon></LocalPhoneIcon>
            0539 381 59 51
          </div>
          <div>
            {" "}
            <EmailIcon>Evliyaturizim@gmail.com</EmailIcon>{" "}
            Evliyaturizim@gmail.com
          </div>
        </div>
        <div className="footer-items">
          {" "}
          <div
            id="footer-map"
            style={{
              width: "100%",
              height: "18em",
            }}
          >
            <CustomGoogleMapWithMarker></CustomGoogleMapWithMarker>
          </div>
        </div>
      </div>
      <div id="social-icons">
        <Link
          id="social-item"
          to="https://www.facebook.com/evliya.turizim"
          target="_blank"
        >
          <FacebookIcon style={{color:'white'}} fontSize="large"></FacebookIcon>
        </Link>
        <Link
          id="social-item"
          to="https://www.instagram.com/evliya_turizm_/"
          target="_blank"
        >
          {" "}
          <InstagramIcon style={{color:'white'}} fontSize="large"></InstagramIcon>
        </Link>
      </div>
      <div id="footer-second">
        Copyright © Evliya Turizm 2024 - Tüm Hakları Saklıdır
      </div>
      <div id="fixed-items">
        <a
          href="https://api.whatsapp.com/send?phone=+905393815951"
          target="_blank"
          className="fixed-item"
          rel="noreferrer"
        >
          <WhatsAppIcon fontSize="large" color="success"></WhatsAppIcon>
        </a>
        <a href="tel:03123109191" onClick={trackPixel} className="fixed-item">
  <LocalPhoneIcon color="primary" fontSize="large"></LocalPhoneIcon>
</a>

        <div className="fixed-item" onClick={() => toTop()}>
          {" "}
          <ArrowUpwardIcon fontSize="large" color="primary"></ArrowUpwardIcon>
        </div>
      </div>
    </div>
  );
};

export default Footer;
