import React from "react";
import kabe from "../../assets/kabekabe.png";
import "./Aboutus.scss";
import bottomImage from "../../assets/about_bottom.png";
const Aboutus = () => {
  return (
    <div id="umre-container">
      <div id="umre-img-cont" style={{ backgroundImage: `url(${kabe})` }}>
    
      </div>
      <div id="about-page">
      <p id="page-titles">HAKKIMIZDA</p>
        <div id="about-text">  
        &nbsp;&nbsp;&nbsp;&nbsp;Hüseyin Yılmaz Turizm Seyahat Acentesi ve Evliya Turizm, Türkiye'nin
          önde gelen seyahat acentelerinden biridir. Kuruluşumuzdan bu yana,
          müşterilerimize kaliteli hizmet sunma ve seyahat deneyimlerini en üst
          düzeye çıkarma amacıyla çalışıyoruz. <br></br> <br></br> Misyonumuz, hac ve umre
          ibadetlerini yerine getirecek olan Müslüman kardeşlerimizin bu kutsal
          yolculuklarını en rahat, güvenli ve konforlu şekilde
          gerçekleştirmelerini sağlamaktır. Bu doğrultuda, seyahatlerimizi
          düzenlerken en yüksek kalite standartlarını benimseyerek, her aşamada
          müşterilerimizin memnuniyetini ön planda tutuyoruz. <br></br> <br></br> Bununla birlikte,
          sadece hac ve umre turizmi ile sınırlı kalmayıp, yurtdışına yapılan
          seyahatlerde de öncü bir rol üstleniyoruz. Kültür gezileri, tarihi ve
          doğal güzellikleri keşfetme turları, iş seyahatleri ve daha birçok
          farklı seyahat türünde müşterilerimize geniş bir yelpaze sunuyoruz. <br></br> <br></br>
          Ayrıca, ülkemizin zengin tarihini ve kültürel mirasını tanıtmak
          amacıyla özenle hazırladığımız kültür turları ile seyahat anlayışına
          yeni bir bakış açısı getiriyoruz. Müşterilerimize, sadece bir tatilden
          öte, unutulmaz bir deneyim sunmayı hedefliyoruz. <br></br> <br></br> Hüseyin Yılmaz Turizm
          Seyahat Acentesi ve Evliya Turizm olarak, profesyonel ve deneyimli
          ekibimizle her müşterimize özel ve kişiselleştirilmiş bir hizmet
          sunmayı taahhüt ediyoruz. Müşteri memnuniyetini en üst düzeyde
          tutarak, seyahatlerimizi güvenilir ve keyifli hale getirmek için
          sürekli olarak çalışıyoruz. Müşterilerimize hayallerindeki seyahat
          deneyimini yaşatmak ve onların beklentilerini aşmak için her zaman en
          iyisini sunmaya kararlıyız.
        </div>
        <img id="bottom-img" alt="bottom-img" src={bottomImage} />
      </div>
    </div>
  );
};

export default Aboutus;
