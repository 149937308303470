import React, { useEffect, useState }  from "react";
import kabe from "../../assets/kudusheader.jpg";
import "./Kudus.scss";
import Card from "../card/Card";
import Slider from "@mui/material/Slider";
import kudusOne from "../../assets/kuduspage.png";
import { Input } from "@mui/material";
const Kudus = () => {
  function valuetext(value) {
    return `${value}`;
  }
  const [gidisTarih, setGidisTarih] = useState("2022-05-09");
  const [donusTarih, setDonusTarih] = useState("2100-05-09");
  const [filtered, setFiltered] = useState([]);
  const [allItems, setAllitems] = useState([]);
  const [priceValue, setPriceValue] = React.useState([1000, 10000]);
  useEffect(() => {
    let startDate = new Date(gidisTarih);
    let endDate = new Date(donusTarih);
    fetch("/login/getAllProducts.php")
      .then((res) => res.json())
      .then((data) => {
        let filteredItems = data.filter((item) => item.tip === "kudus");
        setAllitems(data);
        let items = filteredItems.filter(
          (item) =>
            item.fourPerson >= priceValue[0] && item.fourPerson <= priceValue[1]
        );
        let dateFilter = items.filter((item) => {
          let itemDate = new Date(item.gidis); // Assuming `item.date` is in a format that JavaScript can parse
          return itemDate >= startDate && itemDate <= endDate;
        });

        setFiltered(dateFilter);
      })
      .catch((err) => console.log(err));
  }, [priceValue, gidisTarih, donusTarih]);

  const handleChange = (event, newValue) => {
    setPriceValue(newValue);
  };
  return (
    <div id="kudus-main-cont">
      <div
        id="umre-img-cont"
        style={{
          backgroundImage: `url(${kabe})`,
       
        }}
      ></div>

      <div className="kudus-cont">
        <div id="kudus-text">
          <p id="page-titles">Kudüs: Tarih ve İnancın Buluşma Noktası</p>{" "}
          <p>
          <p>
            İlk yerleşimlerin M.Ö. 3000’li yıllarda başladığı kabul edilen mukaddes şehir Kudüs, bugün İslam dünyasının yetim ve mahzun şehridir. Kudüs, kadim tarihinde birçok medeniyete ev sahipliği yapmıştır. Şehrin bilinen ilk sakinleri Sami asıllı Kenan milletidir. Defalarca kuşatılan, sayısız defa yıkılan ancak tekrar inşa edilen Kudüs; Mısır Krallığı, Hz. Davud Dönemi, Hz. Süleyman Dönemi, Yahuda Krallığı, Babil Krallığı, Pers İmparatorluğu, Makedonya İmparatorluğu (Büyük İskender), Roma İmparatorluğu, Bizans Devleti, Sasaniler, İslam Devleti (Hz. Ömer dönemi), Emeviler, Abbasiler, Fatımiler, Eyyübiler, Moğollar, Memlük Devleti ve Osmanlı İmparatorluğu gibi pek çok dönem ve devlete ev sahipliği yapmıştır.
        </p>
        <p>
            Yaklaşık olarak 250 yıl boyunca Memlük idaresinde kalan Kudüs şehri 1516 yılında Osmanlı hâkimiyetine girmiştir. Mekke-i Mükerreme ve Medine-i Münevvere için Hadim-ül Haremeyn (Harem’in Hizmetçisi) anlayışını benimseyen Osmanlı İmparatorluğu, Kudüs için de aynı hissiyatı gözetmiş ve bu mukaddes belde Osmanlı idaresinde en müreffeh ve huzurlu günlerini yaşamıştır. Osmanlı İmparatorluğu Kudüs’ü tam bir hoşgörü politikasıyla yönetmiş, şehirdeki hiçbir milletin inanç ve gelenekleri yok sayılmamıştır. Kanuni Sultan Süleyman döneminde Yafa Kapısı’nın üzerine “Lâ İlâhe İllallah, İbrahim Halîlullah” yazdırılmış ve üç dinin ortak atası olan Hz. İbrahim öne çıkarılarak burada yaşayan diğer dinlere mensup milletler de gözetilmiştir.
        </p>
        <p>
            1917 yılında Osmanlı idaresinden ayrılan bu görklü ve kutlu şehir; bugün hasretle eski müreffeh ve huzurlu günlerine yeniden kavuşmayı beklemektedir.
        </p>
          </p>
          <h3>İslam'da Kudüs</h3>
          <p>
            Kudüs, İslam dünyası için Mekke ve Medine'den sonra en kutsal üçüncü
            şehirdir. Müslümanlar için büyük bir öneme sahip olan Mescid-i Aksa,
            bu şehirde bulunmaktadır. İslam inancında Kudüs'ün bu kadar önemli
            olmasının birkaç ana sebebi vardır:
          </p>
          <h4>Mescid-i Aksa</h4>
          <p>
            Mescid-i Aksa, Müslümanların ilk kıblesidir. İslam'ın ilk
            yıllarında, Müslümanlar namaz kılarken yüzlerini Kudüs'e, Mescid-i
            Aksa'ya dönerlerdi. Daha sonra kıble, Allah'ın emriyle Mekke'deki
            Kabe'ye çevrilmiştir. Mescid-i Aksa, Kur'an-ı Kerim'de de geçmekte
            ve Müslümanların gönlünde özel bir yer tutmaktadır.
          </p>
          <h4>Miraç Olayı</h4>
          <p>
            Kudüs, Peygamber Efendimiz Hz. Muhammed'in (s.a.v) Miraç'a
            yükseldiği yer olarak kabul edilir. İslam inancına göre, Peygamber
            Efendimiz bir gece Mescid-i Haram'dan (Mekke) Mescid-i Aksa'ya
            (Kudüs) götürülmüş ve buradan göğe yükselmiştir. Bu olay, İslam
            tarihinde Miraç olarak adlandırılır ve büyük bir manevi öneme
            sahiptir. Miraç gecesi, Müslümanlar için önemli bir ibadet ve dua
            gecesidir.
          </p>
          <h4>Tarihi ve Kültürel Zenginlik</h4>
          <p>
            Kudüs, İslam tarihi boyunca birçok önemli olaya tanıklık etmiş bir
            şehirdir. Hz. Ömer döneminde (r.a), Kudüs fethedilmiş ve
            Müslümanların hakimiyetine geçmiştir. Bu dönemde Kudüs'te önemli
            dini ve kültürel yapılar inşa edilmiştir. Mescid-i Aksa ve
            Kubbet-üs-Sahra bu dönemde yapılmış ve İslam mimarisinin önemli
            örnekleri arasında yer almıştır.
          </p>
          <h4>Manevi Deneyim</h4>
          <p>
            Kudüs, Müslümanlar için sadece tarihi ve kültürel değil, aynı
            zamanda manevi bir merkezdir. Bu şehirde ibadet etmek, dua etmek ve
            dini mekanları ziyaret etmek, Müslümanlar için derin bir manevi
            deneyim sunar. Mescid-i Aksa'da namaz kılmak ve Allah'a dua etmek,
            Müslümanların ruhlarını arındırır ve onlara manevi bir huzur verir.
          </p>
          <p>
            Kudüs, sadece tarihi ve dini açıdan değil, aynı zamanda modern
            yaşamın da canlı olduğu bir şehirdir. Pazar yerleri, müzeler,
            restoranlar ve kafeler, ziyaretçilere çeşitli aktiviteler ve
            lezzetler sunar.
          </p>
        </div>
        <div className="image-gallery">
          <img src={kudusOne} alt="Mescid-i Aksa" />
        </div>
      </div>
      <div id="umre-list">
        <div id="filter-cont">
          <div id="value-range">
            <label>Fiyata Göre Filtrele $</label>
            <Slider
              getAriaLabel={() => "Price range"}
              value={priceValue}
              onChange={handleChange}
              valueLabelDisplay="on"
              getAriaValueText={valuetext}
              min={0}
              max={10000}
              style={{ height: "1em" }}
              color="warning"
            />

            <label>Tarihe Göre Filtrele</label>
            <div id="date-inputs">
              <div>
                <h4>Bu Tarihten : </h4>
                <h4>Bu Tarihe : </h4>
              </div>
              <div id="date-input-box">
                <Input
                  type="date"
                  placeholder="En Az"
                  id="low-price"
                  onChange={(e) => setGidisTarih(e.target.value)}
                ></Input>
                <Input
                  type="date"
                  placeholder="En Fazla"
                  id="high-price"
                  onChange={(e) => setDonusTarih(e.target.value)}
                ></Input>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p id="page-titles">KUDÜS TURLARIMIZ</p>{" "}
          {filtered.length || allItems.length !== 0 ? (
            filtered.map((item) => <Card key={item.id} tur={item} />)
          ) : (
            <h1>Yükleniyor...</h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default Kudus;
