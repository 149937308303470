import React from "react";
import "./CarouselSlide.scss";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import kareem from "../../assets/slideTwo.gif";
import slidetwo from "../../assets/slideOne.gif";
import ramadan from "../../assets/slideThree.gif";
import mobileItemOne from "../../assets/mobileCarouselOne.gif";
import mobileItemTwo from "../../assets/mobileCarouselTwo.gif";
import mobileItemThree from "../../assets/mobileCarouselThree.gif";

const CarouselSlide = () => {
  let width = window.screen.width;


  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {width <= 880 ? (
        <Carousel
          dynamicHeight={true}
          showThumbs={false}
          style={{ backgroundAttachment: "fixed"}}
          autoPlay={true}
          infiniteLoop={true}
         interval={6500}
        >
         
          <img key="item-1" src={mobileItemTwo} alt="mobileItemTwo" />
          <img key="item-2" src={mobileItemOne} alt="mobileItemOne" />
          <img key="item-3" src={mobileItemThree} alt="mobileItemThree" />
        </Carousel>
      ) : (
        <Carousel
          dynamicHeight={true}
          showThumbs={false}
          autoPlay={true}
          interval={6500}
          infiniteLoop={true}
        >
          <img key="item-1" src={ramadan} alt="mobileItemOne" />
          <img key="item-2" src={kareem} alt="mobileItemTwo" />
          <img key="item-3" src={slidetwo} alt="mobileItemThree" />
        </Carousel>
      )}
    </div>
  );
};

export default CarouselSlide;
