import React, { useEffect, useState } from "react";
import CarouselSlide from "../carousel/CarouselSlide";
import Card from "../card/Card";
import logo from "../../assets/evliyalogo.png";
import "./Dashboard.scss";
import hac from "../../assets/hac.png";
import umreImg from "../../assets/umre.png";
import ScrollAnimation from "react-animate-on-scroll";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@mui/icons-material/Close";
import cardone from "../../assets/cardone.jpeg";
import cardtwo from "../../assets/kultur.jpg";
import kudus from "../../assets/kudus.jpg";
import "animate.css/animate.compat.css";
import cardoneM from "../../assets/cardoneM.png";
import cardthree from "../../assets/cardtwo.jpeg";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import modalitem from "../../assets/modalitem.jpeg";
import umreimgM from "../../assets/umreimgM.png";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const Dashboard = () => {
  let width = window.screen.width;
  const [open, setOpen] = useState(false);

  const [productList, setProductList] = useState([]);
  useEffect(() => {
    fetch("https://www.evliyaturizm.com/login/getAllProducts.php")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setProductList(data).then(console.log(data));
      })
      .catch((error) => {
        console.log("Fetch error: ", error);
      });
  }, []);

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={{ position: "relative", alignSelf: "center" }}>
      <img id="modal-img" src={modalitem} alt="modal-img" />
      <button
        style={{
          position: "absolute",
          right: "0",
          top: "0",
          backgroundColor: "lightgray",
          border: "none",
          padding: "0",
        }}
        onClick={handleClose}
      >
        {" "}
        <CloseIcon color="success" fontSize="small"></CloseIcon>{" "}
      </button>
    </div>
  );

  const firstSixProducts = productList.slice(-8);
  return (
    <div id="dash-cont">
      <Modal id="modal-cont" open={open} onClose={handleClose}>
        {body}
      </Modal>

      <div>
        {" "}
        <CarouselSlide></CarouselSlide>
      </div>

      <div id="dash-title-logo">
        <img id="mini-logo" alt="mini-logo" src={logo} />

        <div id="dash-card-title">
          Evliya Turizm olarak, her zaman müşterilerimizin yanında olmak,
          onların ihtiyaçlarını anlamak ve en değerli anlarını unutulmaz kılmak
          için çalışıyoruz. Sizlerle birlikte bu manevi yolculuğa çıkmak, bizim
          için bir onurdur ve bu yolda sizinle birlikte olmaktan büyük mutluluk
          duyuyoruz.
        </div>
      </div>

      <div id="dash-cards">
        <Link to="/umre" className="dash-card">
          <ScrollAnimation
          className="animated-cards"
          
            animateIn="fadeIn"
          >
            <div
              id="card-img-dash"
              style={{
                backgroundImage: `url(${cardone})`,
                backgroundPosition: "center",
              }}
            >
              <div id="card-img-op">
                <p>FIRSATLARI İNCELEMEK İÇİN TIKLAYIN</p>
              </div>
            </div>
            <div id="card-text">
              <p>UMRE TURLARI</p>
            </div>
          </ScrollAnimation>
        </Link>
        <Link to="/kudus" className="dash-card">
          {" "}
          <ScrollAnimation
            className="animated-cards"
            animateIn="fadeIn"
          >
            <div
              id="card-img-dash"
              style={{ backgroundImage: `url(${kudus})` }}
            >
              <div id="card-img-op">
                <p>FIRSATLARI İNCELEMEK İÇİN TIKLAYIN</p>
              </div>
            </div>
            <div id="card-text">
              <p>KUDÜS TURLARI</p>
            </div>
          </ScrollAnimation>
        </Link>
        <Link to="/hac" className="dash-card">
          <ScrollAnimation
           className="animated-cards"
            animateIn="fadeIn"
          >
            <div
              id="card-img-dash"
              style={{
                backgroundImage: `url(${cardthree})`,
                backgroundPosition: "center",
              }}
            >
              <div id="card-img-op">
                <p>FIRSATLARI İNCELEMEK İÇİN TIKLAYIN</p>
              </div>
            </div>
            <div id="card-text">
              <p>HAC TURLARI</p>
            </div>
          </ScrollAnimation>
        </Link>
        <Link to="/cultural" className="dash-card">
          <ScrollAnimation
            className="animated-cards"
            animateIn="fadeIn"
          >
            <div
              id="card-img-dash"
              style={{
                backgroundImage: `url(${cardtwo})`,
                backgroundPosition: "center",
              }}
            >
              <div id="card-img-op">
                <p>FIRSATLARI İNCELEMEK İÇİN TIKLAYIN</p>
              </div>
            </div>
            <div id="card-text">
              <p>KÜLTÜR TURLARI</p>
            </div>{" "}
          </ScrollAnimation>
        </Link>{" "}
      </div>
      <ScrollAnimation animateIn="bounceInRight">
        {" "}
        <div
          style={{ backgroundImage: `url(${width >= 880 ? hac : cardoneM})` }}
          id="dash-about-us"
        >
          <div id="dash-about-p-cont">
            <div id="dash-about-title">Hakkımızda</div>{" "}
            <div id="dash-about-p">
              Hüseyin Yılmaz Turizm Seyahat Acentesi ve Evliya Turizm,
              Türkiye'de nitelikli Umre ve Hac ibadetini kalite standartlarını
              yükseltmek amacıyla kurulmuştur.<br></br> <br></br> Misyonumuz,
              hac ve umre turizminin yanı sıra yurtdışına yapılan seyahatlerde
              de kaliteli hizmet sunarak, müşterilerimize unutulmaz deneyimler
              yaşatmaktır. Ayrıca, kültür gezileri düzenleyerek ülkemizin zengin
              tarihini, kültürünü ve doğal güzelliklerini keşfetmelerini
              sağlamak da önceliklerimiz arasındadır.<br></br> <br></br> Bu
              şekilde, seyahat anlayışına yeni bir bakış açısı getirerek,
              müşterilerimize geniş bir yelpazede hizmet sunmayı ve seyahat
              deneyimlerini zenginleştirmeyi hedefliyoruz. Müşteri memnuniyetini
              esas alarak, profesyonel ve deneyimli bir ekiple çalışarak, her
              yolculuğun unutulmaz olmasını sağlamak için çaba harcıyoruz.
            </div>
          </div>
        </div>
      </ScrollAnimation>

      <div className="dash-list-title">
        <span id="dash-title-span">UYGUN FİYATLI</span>
        <p style={{ color: "RED" }}>ÖNE ÇIKAN PAKETLERİMİZ</p>
      </div>

      <div id="dash-card-list">
        {firstSixProducts.length !== 0 ? (
          firstSixProducts.map((item) => <Card key={item.id} tur={item} />)
        ) : (
          <h1>Yükleniyor...</h1>
        )}
      </div>
      <div id="social-medias">
        <Link
          to="https://www.facebook.com/evliya.turizim"
          target="_blank"
          id="face-icon"
        >
          <FacebookIcon style={{ fontSize: 60, height: "100%" , color:'black' }}></FacebookIcon>
        </Link>
        <Link
          to="https://www.instagram.com/evliya_turizm_/"
          target="_blank"
          id="face-icon"
        >
          <InstagramIcon
            style={{ fontSize: 60, height: "100%" , color:'black' }}
          ></InstagramIcon>
        </Link>
        <Link
          href="https://api.whatsapp.com/send?phone=+905393815951"
          target="_blank"
          id="face-icon"
        >
          <WhatsAppIcon style={{ fontSize: 60, height: "100%" , color:'black' }}></WhatsAppIcon>
        </Link>
      </div>
      <ScrollAnimation animateIn="bounceInLeft">
        <div
          style={{
            backgroundImage: `url(${width >= 880 ? umreImg : umreimgM})`,
            alignItems: "end",
          }}
          id="dash-about-us"
        >
          <div id="dash-about-p-cont">
            <div id="dash-about-title">Hac , Umre ;</div>{" "}
            <div id="dash-about-p">
              Evliya Turizm Evliya Turizm, yolculuğunuz boyunca sizi benzersiz
              bir deneyime taşımak için burada! Yılların tecrübesi ve sağlam
              altyapısıyla, kutsal topraklara yapılan hac ve umre
              ziyaretlerinizi unutulmaz kılıyoruz.<br></br> <br></br> Size özel
              tasarlanmış paketlerimizle, dualarınızı ettiğiniz bu kutsal
              mekanları en anlamlı şekilde keşfetmenizi sağlıyoruz. Her aşamada
              yanınızda olan uzman rehberlerimiz, sizleri bu manevi yolculukta
              yönlendiriyor ve destekliyor. Hac ve umre sürecinde
              ihtiyaçlarınızı karşılamak için özenle hazırladığımız
              hizmetlerimizle, konforunuzu ve huzurunuzu en üst seviyede
              tutuyoruz.<br></br> <br></br> Bizimle olan her yolculuk, sadece
              bir gezi değil, aynı zamanda manevi bir dönüşüm ve ruhani bir
              deneyimdir. Evliya Turizm olarak, sizleri bu kutsal topraklara
              yolculuğa davet ediyoruz. Hayallerinizdeki hac ve umre deneyimini
              gerçeğe dönüştürmek için bizimle iletişime geçin ve ruhunuzu
              arındıran bir yolculuğa adım atın!
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
};

export default Dashboard;
