import React, { useEffect, useState } from "react";
import "./Umre.scss";
import kabe from "../../assets/umrekabe.png";
import Card from "../card/Card";
import Slider from "@mui/material/Slider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Input } from "@mui/material";
const Umre = () => {
  function valuetext(value) {
    return `${value}`;
  }
  const [gidisTarih, setGidisTarih] = useState("2022-05-09");
  const [donusTarih, setDonusTarih] = useState("2100-05-09");
  const [filtered, setFiltered] = useState([]);
  const [allItems, setAllitems] = useState([]);
  const [priceValue, setPriceValue] = React.useState([1000, 2500]);
  useEffect(() => {
    let startDate = new Date(gidisTarih);
    let endDate = new Date(donusTarih);
    fetch("/login/getAllProducts.php")
      .then((res) => res.json())
      .then((data) => {
        let filteredItems = data.filter((item) => item.tip === "umre");
        setAllitems(data);
        let items = filteredItems.filter(
          (item) =>
            item.fourPerson >= priceValue[0] && item.fourPerson <= priceValue[1]
        );
        let dateFilter = items.filter((item) => {
          let itemDate = new Date(item.gidis); // Assuming `item.date` is in a format that JavaScript can parse
          return itemDate >= startDate && itemDate <= endDate;
        });

        setFiltered(dateFilter);
      })
      .catch((err) => console.log(err));
  }, [priceValue, gidisTarih, donusTarih]);

  const handleChange = (event, newValue) => {
    setPriceValue(newValue);
  };
  return (
    <div id="umre-container">
      <div id="umre-img-cont" style={{ backgroundImage: `url(${kabe})` }}></div>
      <p id="page-titles">UMRE</p>{" "}
      <div id="accordion-container">
        <Accordion style={{border:'1px solid gray'}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            UMRE NEDİR ?
          </AccordionSummary>
          <AccordionDetails>
            Umre kelimesi, ziyaret etmek anlamına gelmektedir. Dinî bir terim
            olarak umre, ‘Belirli bir zamana bağlı olmaksızın ihrama girerek
            Kâbe’yi tavaf etmek, Safâ ile Merve arasında sa’y yapmak ve tıraş
            olup ihramdan çıkmak suretiyle yerine getirilen ibadet’ demektir.
            Umrenin iki farzı vardır: İhram ve tavaf. Bunlardan ihram şart,
            tavaf rükündür. Vacipleri ise sa’y ile tıraş olup ihramdan
            çıkmaktır. Ömürde bir defa umre yapmak sünnettir. Bazı âlimler farz
            olduğunu söylemişlerdir.
          </AccordionDetails>
        </Accordion>
        <Accordion style={{border:'1px solid gray'}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            Umre Nasıl Yapılır ?
          </AccordionSummary>
          <AccordionDetails>
            * Umre yapmak isteyen kimse; gerekiyorsa koltuk altı ve kasık
            kıllarını giderir, saç sakal tıraşı olur, bıyıklarını düzeltir,
            tırnaklarını keser ve boy abdesti alır, boy abdesti alma imkânı
            yoksa abdest alır, vücuduna güzel koku sürünür. Erkekler, atlet,
            kilot, çorap, elbise ve ayakkabılarını çıkarırlar. İzar ve rida adı
            verilen iki parça ihram örtüsüne bürünürler. Ridanın uçlarını
            birbirine bağlamak veya iğne ile tutturmak mekruhtur. Ayaklarına
            arkası ve üzeri açık terlik giyerler. Bele kemer bağlamada, sırta
            çanta almada ve şemsiye kullanmada bir sakınca yoktur. Kadınlar
            elbise ve ayakkabılarını giymeye devam ederler, başlarını açmazlar,
            yüzlerini de örtmezler.<br></br>
            <br></br> * İhramın sünneti niyeti ile iki rekât ihram namazı
            kılarlar. Namazın birinci rekâtında Fatiha suresinden sonra
            "kafirun" suresini, ikinci rekâtında ise yine Fatiha suresinden
            sonra "ihlâs" suresini okurlar. <br></br>
            <br></br>* Umre yapmak isteyen kimse afakî ise mikat sınırlarını
            geçmeden, Hıll bölgesinde ikamet ediyorsa bulunduğu yerde, Harem
            bölgesinde bulunuyorsa Hıll bölgesinde mesela Ten'im'e giderek
            ihrama girer.<br></br>
            <br></br> * İhrama, niyet etmek ve telbiye getirmek suretiyle
            girilir. Niyet, umre yapacağının kalben belirlenmesi demektir.
            Niyetin, "Allah'ım! Umre yapmak istiyorum. Onu bana kolaylaştır ve
            onu kabul buyur" diyerek diliyle ifade edilmesi müstehaptır.
            <br></br>
            <br></br> * Niyet ettikten sonra, "Buyur Allah'ım buyur! Buyur,
            senin hiçbir ortağın yoktur. Buyur, şüphesiz her türlü övgü, nimet,
            mülk ve hükümranlık sana mahsustur. Senin ortağın yoktur" diyerek
            telbiye getirir. Böylece ihrama girmiş ve ihram yasakları başlamış
            olur.<br></br> <br></br>* Mekke'ye varıncaya kadar vasıtalara
            binişte ve indiği yerde, kafilelerle karşılaştığında, şehirlere
            girdiğinde, akşam ve sabah, gece ve gündüz, vasıtada, yürürken,
            otururken, yatarken, ayakta iken, inişte, yokuşta, mekan değiştikçe
            ve farz namazların arkasından her fırsatta telbiye, tekbir, tehlil
            ve salavat-ı şerife yüksek sesle söyleyerek yolculuğuna devam eder.{" "}
            <br></br>
            <br></br>* Telbiyeyi her söyleyişte üç defa tekrarlamak, sonra
            tekbir, tehlil ve salâvat-ı şerife okumak müstehaptır. <br></br>
            <br></br>* Mekke'ye yaklaşıp Harem bölgesine girince "Allah'ım!
            Burası senin haremindir, emin kıldığın yerdir. Beni cehenneme
            girmekten koru. Kullarını dirilttiğin gün beni azabından güvende
            kıl, beni dostlarından ve itaatkâr olanlardan eyle" diye dua eder.{" "}
            <br></br>
            <br></br>* Mekke'ye abdestli girmek sünnet, gündüz girmek
            müstehaptır. <br></br>
            <br></br>* Mekke'de otele veya eve yerleşip dinlendikten sonra
            mümkünse boy abdesti, mümkün değilse abdest alır, yaya veya vasıta
            ile Mescid-i Harem'e gider. Yolda tekbir, tehlil, telbiye ve
            salavat-ı şerife getirir. Tevazu ve saygı ile "Allah'ım! Rahmet
            kapılarını bana aç ve beni kovulmuş şeytandan koru" diye dua ederek
            Mescid-i Harem'e girer. <br></br>
            <br></br>* Beytullah'ı görünce üç defa tekbir ve tehlil getirir ve
            şu duayı okur. "Allah'ı noksan sıfatlardan tenzih ederim, Her türlü
            övgü Allah'a mahsustur, Allah'tan başka ilah yoktur. Allah en
            büyüktür. Allah'ım! Bu senin Evindir. Onu Sen yücelttin, Sen
            şereflendirdin, Sen değerli yaptın. Onun yüceliğini, şerefini ve
            değerini artır. Ya Rabbi! Onun değerini artıran, onu şereflendiren,
            ona saygı gösteren kimsenin şerefini, saygınlığını, heybetini,
            yüceliğini ve iyiliğini artır. Allah'ım! Sen selamsın ve selamet
            ancak sendendir. Bizi selametle yaşat ve selamet yurdun olan
            cennetine koy, ey Celal ve ikram sahibi Allah'ım! Sen her şeyden
            yücesin ve her şeyden üstünsün" <br></br>
            <br></br>* Bildiği başka duaları da okuyabilir. Tavafa başlamadan
            önce telbiyeyi keser. Hacer-i Esved hizasına gelir, yönünü ona
            döner, ellerini omuz hizasına kadar kaldırıp "Bismillah, Allahu
            Ekber" diyerek Hacer-i Esved'i selamlar, tekbir, tehlil ve tahmid
            getirir. Kalabalık değilse ve kimseye eziyet vermeyecekse Hacer-i
            Esved'i öper, kalabalık ise Hacer-i Esved'i öpmez. İstilam, sünnet,
            insanları itip kakmak ve eziyet vermek günahtır. Sünneti ifa etmek
            için günah işlenmez. * Umre tavafı yapmaya niyet eder. Niyetini,
            "Allah'ım! Senin için umre tavafını yedi şavt olarak yapmak
            istiyorum. Onu benim için kolaylaştır ve kabul eyle" diyerek yapması
            müstehaptır. <br></br>
            <br></br>* Tavafını, Ka'be'yi soluna alıp Hatim'in dışından
            dolanarak yapar. Her şavtta Rükn-i Yemani ve Hacer-i Esved'i uzaktan
            "Bismillah, Allahu Ekber" diyerek istilamda bulunur. Hacer-i Esved'i
            istilam, sünnet, Rükn-i Yemani'yi istilam ise müstehaptır. Rükn-i
            Yemani öpülmez, diğer köşeler istilam edilmez. <br></br>
            <br></br>* Tavaf sırasında mesnun duaları veya bildiği duaları okur
            ve sessizce tekbir ve tehlil getirir veya Kur'an okur.<br></br>{" "}
            <br></br>* Tavafın ilk dört şavtı farz; tavaf'ı cünüp, adetli ve
            nifas halinde değilken ve abdestli olarak yapmak, avret yerlerini
            örtmek, tavafı Ka'be'yi soluna alarak yapmak, tavafa Hacer-i Esved
            hizasından başlamak, tavafı Hatim'in dışından dolanarak yapmak, gücü
            yetenin tavafı yürüyerek yapması ve şavtı yediye tamamlamak
            vaciptir. Bunlardan biri terk edilirse dem gerekir. * Tavaf'ta
            ıztıba' ve ilk üç şavtta remel yapar.
            <br></br>
            <br></br>* Tavaf yedi şavta tamamladıktan sonra "Mültezem"de ve
            Hatim'de dua eder. Mümkünse Makam-ı İbrahim'in arkasında değilse
            uygun bir yerde iki rekat "tavaf namazı" kılar, bu namazı kılmak
            vaciptir. Namazdan sonra dua eder, peşinden zemzem içer ve Hacer-i
            Esved'i istilam eder.
            <br></br>
            <br></br>* Umrenin sa'yini yapmak üzere Safa'ya gider. Yönünü
            Ka'be'ye döner, tekbir, tehlil, tahmid ve salât-ü selam getirir, dua
            eder. Sa'y yapmaya niyet eder. Niyetini, "Allah'ım! Senin rızan için
            Safa ile Merve arasında yedi şavt olarak umrenin sa'yini yapmaya
            niyet ediyorum" diyerek yapması müstehaptır. <br></br>
            <br></br>* Sa'yini yedi şavt olarak Safa'da başlayıp Merve'de
            bitirir. Sa'y yaparken mesnun duaları veya bildiği duaları okur ve
            sessizce tekbir ve tehlil getirir veya Kur'an okur. İki yeşil ışık
            arasında "hervele" yapar. Sa'yi tamamlayınca Merve'de dua eder.{" "}
            <br></br>
            <br></br>* Umre'nin sa'yi vaciptir. Terk edilirse dem gerekir. *
            Berberde veya evde veya otelde saç tıraşı olur veya saçlarını
            kısaltır, böylece ihramdan çıkar ve bu şekilde umre ibadetini yapmış
            olur. <br></br>
            <br></br>* Kadınlar, remel ve hervele yapmazlar. Tekbir, tehlil ve
            telbiyede seslerini yükseltmezler. İhramdan çıkmak için saçlarının
            ucundan parmak ucu kadar kesmeleri yeterlidir. Kadınlar adetli iken
            tavaf yapmazlar.
          </AccordionDetails>
        </Accordion>
        <Accordion style={{border:'1px solid gray'}} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            Umrenin Fazileti
          </AccordionSummary>
          <AccordionDetails>
            Umrenin faziletine ilişkin bazı hadis-i şerifler vardır.Bunlardan
            ikisinin anlamı şöyledir: “Umre, diğer bir umre ile arasındaki
            günahları siler.”( Müslim, Hac, 437 (Hadis No:1349) “Ramazan’da
            yapılan umrenin sevabı bir haccın sevabına denktir.”( İbn-i Mace,
            Menasik, 45 (Hadis No:2991)
          </AccordionDetails>
       
        </Accordion>
      </div>
      <div id="umre-list">
        <div id="filter-cont">
          <div id="value-range">
            <label>Fiyata Göre Filtrele $</label>
            <Slider
              getAriaLabel={() => "Price range"}
              value={priceValue}
              onChange={handleChange}
              valueLabelDisplay="on"
              getAriaValueText={valuetext}
              min={800}
              max={3500}
              style={{ height: "1em" }}
              color="warning"
            />

            <label>Tarihe Göre Filtrele</label>
            <div id="date-inputs">
              <div>
                <h4>Bu Tarihten : </h4>
                <h4>Bu Tarihe : </h4>
              </div>
              <div id="date-input-box">
                <Input
                  type="date"
                  placeholder="En Az"
                  id="low-price"
                  onChange={(e) => setGidisTarih(e.target.value)}
                ></Input>
                <Input
                  type="date"
                  placeholder="En Fazla"
                  id="high-price"
                  onChange={(e) => setDonusTarih(e.target.value)}
                ></Input>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p id="page-titles">UMRE TURLARIMIZ</p>{" "}
          {filtered.length || allItems.length !== 0 ? (
            filtered.map((item) => <Card key={item.id} tur={item} />)
          ) : (
            <h1>Yükleniyor...</h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default Umre;
