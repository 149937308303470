import "./App.css";
import Navbar from "../navbar/Navbar";
import Dashboard from "../dashboard/Dash";
import { Routes, Route } from "react-router-dom";
import Umre from "../umre/Umre";
import Hac from "../hac/Hac";
import Knowledge from "../knowledge/Knowledge";
import Aboutus from "../Aboutus/Aboutus";
import Contact from "../contact/Contact";
import Footer from "../footer/Footer";
import ProductPage from "../productPage/ProductPage";
import Kudus from "../kudus/Kudus";
import Cultural from "../cultural/Cultural";
function App() {
  return (
    <div>
      <Navbar></Navbar>

      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/umre" element={<Umre />} />
        <Route path="/hac" element={<Hac />} />
        <Route path="/knowledge" element={<Knowledge />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/kudus" element={<Kudus />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/cultural" element={<Cultural />} />
        <Route path="/product-detail/:id" element={<ProductPage />} />
      </Routes>

      <Footer></Footer>
    </div>
  );
}

export default App;
