import React, { useEffect, useState } from "react";
import "./Hac.scss";
import kabe from "../../assets/kabekabe.png";
import Card from "../card/Card";
import Slider from "@mui/material/Slider";
import { Input } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Hac = () => {
  function valuetext(value) {
    return `${value}`;
  }
  const [gidisTarih, setGidisTarih] = useState("2020-05-09");
  const [donusTarih, setDonusTarih] = useState("2064-05-09");
  const [filtered, setFiltered] = useState([]);
  const [allItems, setAllitems] = useState([]);
  const [priceValue, setPriceValue] = React.useState([0, 10000]);
  useEffect(() => {
    let startDate = new Date(gidisTarih);
    let endDate = new Date(donusTarih);
    fetch("/login/getAllProducts.php")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })

      .then((data) => {
        let filteredItems = data.filter((item) => item.tip === "hac");
        setAllitems(data);
        let items = filteredItems.filter(
          (item) =>
            item.fourPerson >= priceValue[0] && item.fourPerson <= priceValue[1]
        );
        let dateFilter = items.filter((item) => {
          let itemDate = new Date(item.gidis); // Assuming `item.date` is in a format that JavaScript can parse
          return itemDate >= startDate && itemDate <= endDate;
        });

        setFiltered(dateFilter);
      })
      .catch((err) => console.log(err));
  }, [priceValue, gidisTarih, donusTarih]);

  const handleChange = (event, newValue) => {
    setPriceValue(newValue);
  };

  return (
    <div id="umre-container">
      <div id="umre-img-cont" style={{ backgroundImage: `url(${kabe})` }}></div>
      <p id="page-titles">HAC</p>{" "}
      <div id="accordion-container">
        <Accordion style={{border:'1px solid gray'}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            HAC NEDİR ?
          </AccordionSummary>
          <AccordionDetails>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp; Hac; kutsal kabul edilen mekânları dinî
              maksatla ziyaret etmedir. Hac; İslâm'ın beş şartından biridir. Hac
              kelimesi İbrânîce'de hag şeklindedir; "bayram" anlamına gelen bu
              kelime "bir şeyin etrafında dönmek, dolanmak" mânasındaki hvg
              kökünden türemiştir. Hac veya hag çok eski bir Sâmî tabir olup
              İbrânîce'den başka Ârâmîce'de ve Sâbiî dilinde de bulunmaktadır.
              Kelimenin asıl anlamının "bir şeyin etrafında dönme, dolaşma ve
              halka oyunu" olduğu, daha sonra bayram mânasını kazandığı
              belirtilmektedir.Arapça'da "gitmek, yönelmek; ziyaret etmek"
              anlamlarına gelen hac kelimesi, fıkıh terimi olarak imkânı olan
              her müslümanın belirlenmiş zaman içinde Kâbe'yi, Arafat, Müzdelife
              ve Mina'yı ziyaret etmek ve belli bazı dinî görevleri yerine
              getirmek suretiyle yaptığı ibadeti ifade eder. Bu ibadeti yerine
              getirenlere Arapça'da hâc (çoğulu huccâc), Türkçe'de hacı denir.{" "}
            </p>

            <p>
              <em>
                (Diyanet İslam Ansiklopedisi "Hac" maddesi, 14. Cilt, Ömer Faruk
                Harman)
              </em>
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{border:'1px solid gray'}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            Hac Nasıl Yapılır ?
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <h1>HAC NASIL YAPILIR?</h1>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;Temettü, Kıran ve İfrad olmak üzere üç
                çeşit hac olmakla birlikte, Türkiye'den giden hacıların
                çoğunluğu Temettü haccı yaptıkları için aşağıda Temettü haccının
                tarifi yapılmıştır. Diğer hac çeşitleri ile ilgili bilgi almak
                isteyenler Diyanet İşleri Başkanlığı Hac İlmihaline
                bakabilirler.
              </p>

              <h2>TEMETTU HACCI</h2>
              <p>
                Temettu haccı; hac mevsimi içinde umre yapıp ihramdan çıktıktan
                sonra vakti gelince ihrama girip hac yapmaktan ibarettir.
                Temettu haccı yapacak olan kimse hazırlık safhasından sonra
                umreye niyet eder.
              </p>

              <h3>a. İhrama Girme</h3>
              <p>
                Temettu haccı yapacak kimse mîkât sınırlarını geçmeden veya hava
                alanında ihrama girer. İhrama, niyet etmek ve telbiye getirmek
                suretiyle girilir. Bundan sonra ihram yasakları başlamış olur.
              </p>

              <p>
                <strong>Telbiye:</strong>
                لَبَّيْكَ اَللّٰهُمَّ لَبَّيْكَ، لَبَّيْكَ لَا شَرِيكَ لَكَ
                لَبَّيْكَ، إِنَّ الْحَمْدَ وَ النِّعْمَةَ لَكَ وَ الْمُلْكَ، لَا
                شَرِيكَ لَكَ. (Lebbeyk Allahümme lebbeyk, lebbeyke lâ şerîke
                leke lebbeyk, innel hamde ve’n-ni’mete leke ve’l mülk, lâ şerîke
                lek) Buyur Allahım buyur! Emrindeyim buyur! Senin hiçbir ortağın
                yoktur. Emrindeyim buyur! Şüphesiz hamd sana mahsustur. Nimet de
                senin, mülk de senindir. Senin hiçbir ortağın yoktur.
              </p>

              <p>
                <strong>Niyet:</strong>
                اَللّٰهُمَّ إِنِّي أُرِيدُ الْعُمْرَةَ فَيَسِّرْهَا لِي وَ
                تَقَبَّلْهَا مِنِّي. Allah’ım Umre yapmak istiyorum. Bunu bana
                kolaylaştır ve kabul eyle.
              </p>

              <p>
                Kâ’be’ye varıncaya kadar her fırsatta yüksek sesle telbiye,
                tekbir, tehlîl ve salavât-ı şerife yüksek sesle8 getirerek
                yolculuğa devam eder. Kadınlar telbiye, tekbir, tehlîl ve
                salavât-ı şerife’yi yüksek sesle söylemezler. Mekke’ye yaklaşıp
                Harem bölgesine girince, “Allah’ım! Burası senin haremindir,
                emin kıldığın yerdir. Beni cehenneme girmekten koru. Kullarını
                dirilttiğin gün beni azabından güvende kıl, beni dostlarından ve
                itaatkâr olanlardan eyle” diye dua eder. Mekke’ye abdestli
                girmek sünnet, gündüz girmek müstehaptır. Mekke’de otele veya
                eve yerleşip dinlendikten sonra mümkünse boy abdesti, mümkün
                değilse abdest alır, yaya veya vasıta ile Mescid-i Haram’a
                gider. Tekbir, tehlil ve salavat-ı şerîfe okuyarak yola devam
                eder. “Allah’ım! Rahmet kapılarını bana aç ve beni kovulmuş
                şeytandan koru” diye dua ederek Mescid-i Haram’a girer.
                Beytullah’ı görünce üç defa tekbir ve tehlil getirir ve şu duayı
                okur:
              </p>

              <p>
                “Allah, her türlü noksan sıfatlardan münezzehtir. Her türlü övgü
                Allah’a mahsustur, Allah’tan başka ilah yoktur. Allah en
                büyüktür. Allah’ım! Bu senin Evindir. Onu Sen yüceltin, Sen
                şereflendirdin, Sen değerli yaptın. Onun yüceliğini, şerefini ve
                değerini artır. Ya Rabbi! Onun değerini artıran, onu
                şereflendiren, ona saygı gösteren kimsenin şerefini,
                saygınlığını, heybetini, yüceliğini ve iyiliğini artır.
                Allah’ım! Sen selamsın ve selamet ancak sendendir. Bizi
                selametle yaşat ve selamet yurdun olan cennetine koy Ey Celal ve
                ikram sahibi Allah’ım! Sen her şeyden yücesin ve her şeyden
                üstünsün.”
              </p>

              <p>
                Bildiği başka duaları da okuyabilir. Tavafa başlamadan önce
                telbiyeyi keser.
              </p>

              <h3>b. Tavaf</h3>
              <p>
                Hacer-i Esved hizasına gelir, yönünü Hacer-i Esved’e döner,
                ellerini omuz hizasına kadar kaldırıp “Bismillahi Allahü Ekber”
                diyerek Hacer-i Esved’i selamlar ve umre tavafına niyet edip
                tavafa başlar. Tavaf ederken mesnun olan veya bildiği duaları
                okur veya sessizce tekbir ve tehlil getirir veya Kur’an okur.
                Tavaf’ta “ıztıbâ” ve ilk üç şavtta “remel” yapar. “Metaf”ta
                izdiham varsa “remel” yapmaz. Çünkü kalabalıkta remel yapmak
                mümkün olmaz. Remel yapacağım diye insanlara eziyet vermek
                kesinlikle doğru değildir. Tavaftan sonra eğer imkan varsa
                “Mültezem”de ve Hatîm’de dua eder. Yine mümkünse Makam-ı
                İbrahim’in arkasında, değilse uygun bir yerde iki rekat “tavaf
                namazı” kılar. Tavaf namazında birinci rek’atta Fatiha
                suresinden sonra “Kâfirûn”, ikinci rekatta “İhlas” suresini
                okur. Namazdan sonra dua eder. Sonra Hacer-i Esved’in hizasına
                gelip istilam eder.
              </p>

              <h3>c. Sa’y</h3>
              <p>
                Umrenin sa’yini yapmak üzere Safa’ya gider. Yönünü Kâ’be’ye
                döner, tekbir, tehlil, tesbih ve salat ü selam getirir, ellerini
                açıp dua eder, sonra “Allah’ım! Senin rızan için Safa ile Merve
                arasında yedi şavt olarak umrenin sa’yini yapmak istiyorum”
                diyerek Sa’y yapmaya niyet eder.
              </p>

              <h3>d. Hac İçin İhrama Girmek</h3>
              <p>
                “Terviye” günü (8 Zilhicce) “hacca hazırlık” başlığı altında
                zikrettiğimiz hazırlığı yapar. İki rekat “ihram namazı” kılar,
                hac yapmaya “Allah’ım! Hac yapmak istiyorum. Onu bana
                kolaylaştır ve onu kabul buyur” diyerek hacca niyet eder ve
                telbiye getirir. Böylece hac ihramına girmiş olur ve ihram
                yasakları başlar. Bu arada arzu eder ve vakit de müsait olursa
                nafile bir tavaftan sonra haccın sa’yini yapabilir. Bayramın
                birinci günü Cemre-yi Akabe’ye taş atıncaya kadar her fırsatta
                telbiye, tekbir, tehlîl ve salavât-ı şerife getirir.
              </p>

              <h3>e. Arafat Vakfesi</h3>
              <p>
                Arafat Vakfesi Terviye günü (zilhicce 8) sabah namazından sonra
                Mina’ya gidip burada gecelemek ve Arefe günü (zilhicce 9) sabah
                namazından sonra Arafat’a gitmek sünnettir. Ancak günümüzde
                izdiham nedeniyle terviye günü sabah namazından itibaren gündüz
                veya gece otobüslerle doğrudan Arafat’a çıkılmaktadır. Arafat’ta
                arefe günü öğle vaktine kadar çadırlarda vaktini namaz kılarak,
                Kur’an okuyarak, dua, zikir ve tövbe ederek, yapılan vaazları ve
                konuşmaları dinleyerek geçirir. Abdestli bulunmaya özen
                gösterir. Öğle vakti yaklaşınca abdestsiz ise abdest alır,
                namaza hazırlanır. Öğle namazını öğle vaktinde ikindi namazı ile
                birlikte cem-i takdim ile kılar. Namazdan sonra ayakta kıbleye
                dönerek “Arafat Vakfesi” yapar. Güneşin batmasına kadar
                Arafat’ta kalır, vaktini ibadetle geçirir. Güneş battıktan sonra
                Müzdelife’ye hareket eder. Akşam namazını Arafat’ta ve yolda
                kılmaz.
              </p>

              <h3>f. Müzdelife Vakfesi</h3>
              <p>
                Müzdelife'de uygun bir yere yerleşir. Burada akşam ve yatsı
                namazlarını yatsı vaktinde cem-i te’hîr ile kılar. Müzdelife’de
                geceler. Vaktini namaz, dua, zikir ve Kur’an okuyarak geçirir.
                Sabah namazını erkence kılar, namazdan sonra “Müzdelife
                Vakfesi”ni ayakta yapar, dua eder. Güneş doğmadan önce Mina’ya
                hareket eder. Günümüzde Müzdelife Vakfesi; yer darlığı ve
                izdiham nedeniyle Arefe günü güneşin batmasından sonra bayramın
                birinci günü güneşin doğmasına kadar olan zaman diliminde
                yapılmaktadır.
              </p>

              <h3>g. Akabe Cemresine Taş Atmak</h3>
              <p>
                Müzdelife vakfesini müteakiben Akabe Cemresi’ne gider ve yedi
                taş atar. Taş attıktan sonra imkan varsa dua eder. İzdiham yahut
                yoğunluk varsa beklemeksizin oradan uzaklaşır. Böylesi
                durumlarda dua yürürken de yapılabilir. Cemre-yi Akabe’ye ilk
                taşın atılmasıyla telbiyeye son verilir. Şeytan taşlama görevini
                sağlığı yerinde olan kimsenin bizzat kendisinin yapması gerekir.
                Hastalar vekalet ile taşlarını attırabilirler.
              </p>

              <h3>h. Şükür Kurbanı Kesmek</h3>
              <p>
                Şeytana taş attıktan sonra Harem bölgesi sınırları içinde kurban
                keser veya vekâlet yolu ile kestirir. Bu kesilen kurban şükür
                kurbanıdır (hedy).
              </p>

              <h3>ı. Tıraş Olmak</h3>
              <p>
                Kurban kestikten sonra saç tıraşı olur veya saçını kısaltır ve
                böylece ihramdan çıkmış olur. Cinsel ilişki dışındaki diğer
                ihram yasakları kalkar. “Şeytan taşlama”, “kurban kesme” ve
                “tıraş olma” görevleri arasında sıraya uymak cumhura göre
                “sünnet”, sadece Ebû Hanîfe’ye göre “vacip”tir. Günümüzde
                kurbanlar İslam Bankası aracılığı ile kestirildiğinden bu üç
                görev arasındaki tertibe uyulması zaman almaktadır. Bu itibarla
                “şeytan taşlama” ve “tıraş olma” arasındaki sıraya uymak
                yeterlidir. Bayramın 2, 3 ve 4. günleri şeytan taşlama zamanı,
                ittifakla öğle ezanının okunmasından itibaren başlar, fecr-i
                sadığa kadar devam eder.
              </p>

              <h3>i. Ziyaret Tavafı</h3>
              <p>
                Bayramın birinci günü “şeytan taşlama”, “kurban kesme” ve “tıraş
                olma” görevlerini yaptıktan sonra aynı gün imkân olursa,
                Mekke’ye gider ve farz olan ziyaret tavafını yapar. Bu tavafın
                en geç bayramın 3. günü güneş batımından önce yapılması cumhura
                göre sünnet, Ebû Hanîfe’ye göre vâciptir. Tavafa, “Allah’ım!
                Yedi şavt olarak haccın tavafını yapmak istiyorum. Onu bana
                kolaylaştır ve onu kabul buyur” diye niyet eder. Tavafı daha
                önce tarif edildiği şekilde yapar. Hac sa’yi’ni daha önce
                yapmamış ise tavafta “ızdıba” ve ilk üç şavtta “remel” yapar.
                “Allah’ım! Rızan için Safa ile Merve arasında yedi şavt olarak
                haccın sa’yini yapmak istiyorum. Onu bana kolaylaştır ve onu
                kabul buyur” diyerek niyet eder ve usulüne uygun olarak sa’yini
                yapar.
              </p>

              <h3>j. Haccın Sa'yini Yapmak</h3>
              <p>
                Esasında haccın sa'yini imkan bulanlar Arafat vakfesinden önce
                geçerli bir tavaf yaptıktan sonra da yapabilirler. Fakat
                vakfeden önce sa'y yapamayanlar, vakfeden sonra bu sa'yi
                gerçekleştirebilirler. Sa’y yaparken mesnun veya bildiği duaları
                okur, sessizce tekbir, tehlil, tesbîh ve tahmîd getirir veya
                Kur’an okur. Her şavtta yeşil ışıklı sütunlar arasında “hervele”
                yapar. Sa’yi tamamlayınca Merve tepesinde dua eder.
              </p>

              <h3>k. Cemrelere Taş Atmak</h3>
              <p>
                Bayramın 2. ve 3. günleri zeval vaktinden sonra sırayla Küçük,
                Orta ve Akabe cemrelerine yedişer taş atar. Küçük ve Orta
                cemrelere taş attıktan sonra uygun bir yere çekilerek dua eder.
                Akabe Cemresine taş attıktan sonra dua etmez ve oradan hemen
                ayrılır. Bu iki gün zevalden önce “şeytan taşlama” yapılmaz.
                Yapılmış ise zevalden sonra yeniden atılır. Bayramın 3. günü
                Mina’da kalmayıp Mekke’ye gidecek olursa 4. günün taşları uygun
                bir yere bırakılır. Mekke’den ayrılacakları güne kadar ibadet,
                tavaf, zikir, dua ve Kur’an okuma ile meşgul olurlar.
              </p>

              <h3>l. Veda Tavafı</h3>
              <p>
                Bütün işlerini bitirdikten sonra Mekke’den ayrılmadan önce “vedâ
                tavafı yapar”, böylece hac görevini tamamlamış olarak
                memleketine veya Medine’ye gider.
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{border:'1px solid gray'}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            Umrenin Fazileti
          </AccordionSummary>
          <AccordionDetails>
            <h1>Hac ve Önemi</h1>
            <p>
              Hac, dünya Müslümanlarının kaynaşmasını, birbirlerini ve değişik
              kültürleri tanımalarını sağlar. İslâmî bilinçlenmeye, imanın
              aksiyona geçirilmesine, manevî kirlerden arınmaya, gönlü bütün
              safiyeti ile Yaratan’a açmaya vesile olur.
            </p>

            <p>
              “Kim Allah için hacceder de (Allah’ın rızâsına uymayan) kötü söz
              ve davranışlardan ve Allah’a karşı gelmekten sakınırsa, (kul hakkı
              hariç) annesinin onu doğurduğu günkü gibi (günahlarından arınmış
              olarak hacdan) döner. (Buhârî, Hac, 4)”
            </p>

            <p>
              “Hacılar ve umre yapanlar Allah’ın (evinini) ziyaretçileridir.
              Kendisine dua ederlerse dualarına icabet eder, On’dan bağışlanma
              dilerlerse onları bağışlar” (İbn Mâce, Menasik, 5) anlamındaki
              hadislerde de ifade edildiği gibi hacda yapılan dualar ve tövbeler
              kabul görür. Böylece bu ibadeti îfa edenler, işlemiş oldukları
              hata ve günahlarından arınarak hayata yeni bir canlılık ve şuurla
              dönerler.
            </p>

            <p>
              Hac en fazîletli ibadetlerden biridir. Bir sahâbî, “Ey Allah’ın
              Elçisi! En fazîletli amel hangisidir” diye sordu. Hz. Peygamber,
              “Allah’a imandır” buyurdu. Sahâbî, “Sonra hangisidir” diye sordu.
              Hz. Peygamber, “Allah yolunda cihâd etmektir” buyurdu. Sahâbî,
              “Sonra hangisidir” diye sordu. Hz. Peygamber, “Makbul bir hacdır”
              buyurdu. (Nesâî, Menâsikü’l-Hac, 4)
            </p>

            <p>
              Peygamberimizin beyanı ile, “Makbul bir haccın mükafatı da ancak
              cennettir.” (Müslim, Hac, 437)
            </p>

            <p>
              <em>(Bu bölüm Diyanet Hac İlmihalinden alınmıştır)</em>
            </p>
          </AccordionDetails>
        </Accordion>
      </div>
      <div id="umre-list">
        <div id="filter-cont">
          <div id="value-range">
            <label>Fiyata Göre Filtrele $</label>
            <Slider
              getAriaLabel={() => "Price range"}
              value={priceValue}
              onChange={handleChange}
              valueLabelDisplay="on"
              getAriaValueText={valuetext}
              min={0}
              max={10000}
              style={{ height: "1em" }}
              color="warning"
            />

            <label>Tarihe Göre Filtrele</label>
            <div id="date-inputs">
              <div>
                <h4>Bu Tarihten : </h4>
                <h4>Bu Tarihe : </h4>
              </div>
              <div id="date-input-box">
                <Input
                  type="date"
                  placeholder="En Az"
                  id="low-price"
                  onChange={(e) => setGidisTarih(e.target.value)}
                ></Input>
                <Input
                  type="date"
                  placeholder="En Fazla"
                  id="high-price"
                  onChange={(e) => setDonusTarih(e.target.value)}
                ></Input>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p id="page-titles">HAC TURLARIMIZ</p>{" "}
          {filtered.length || allItems.length !== 0 ? (
            filtered.map((item) => <Card key={item.id} tur={item} />)
          ) : (
            <h1>Yükleniyor...</h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default Hac;
