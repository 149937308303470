import React, { useEffect } from "react";
import "./ProductPage.scss";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PersonIcon from "@mui/icons-material/Person";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import { useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HotelIcon from '@mui/icons-material/Hotel';
const ProductPage = () => {
  let width = window.screen.width;
  const [value, setValue] = React.useState("1");
  const [hediye, setHediye] = React.useState([]);
  const [dahil, setDahil] = React.useState([]);
  const [gerekli, setGerekli] = React.useState([]);
  const [dahildegil, setDahildegil] = React.useState([]);
  const [iptalsarti, setIptalsarti] = React.useState([]);
  const [cocuk, setCocuk] = React.useState([]);
  const [detay, setDetay] = React.useState([]);
  const [currentProduct, setCurrentProduct] = React.useState({});
  let { id } = useParams();
  const [isLoading, setIsLoading] = React.useState(true);
  useEffect(() => {
    fetch(`/login/getproductbyid.php?id=${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text(); // convert to plain text
      })
      .then((text) => {
        const cleanedText = text.replace(/,\s*$/, "");
        return JSON.parse(cleanedText); // then parse it to JSON
      })
      .then((data) => {
        setCurrentProduct(data);
        setHediye(data.hediye ? data.hediye.split("\n") : []);
        setDahil(data.dahil ? data.dahil.split("\n") : []);
        setDahildegil(data.dahildegil ? data.dahildegil.split("\n") : []);
        setGerekli(data.gerekli ? data.gerekli.split("\n") : []);
        setIptalsarti(data.iptalsarti ? data.iptalsarti.split("\n") : []);
        setCocuk(data.cocuk ? data.cocuk.split("\n") : []);
        setDetay(data.detay ? data.detay.split("\n") : []);
      })
      .catch((err) => {
        console.error("Fetch operation failed:", err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div id="product-cont">
      <div id="product-details">
        <img
          id="product-detail-img"
          alt="product-img"
          src={`/login/${currentProduct.img}`}
        ></img>
        <div id="product-text-cont">
          <h2 id="product-title">{currentProduct.tur_ismi}</h2>
          <div id="product-prices">
           
            <div className="price-items">
              {" "}
              <p id="price-title"> {currentProduct.twoPerson}$</p>
              <PersonIcon></PersonIcon> <PersonIcon></PersonIcon>
              <p>İki Kişilik Oda</p>
            </div>
            <div className="price-items">
              {" "}
              <p id="price-title">{currentProduct.onePerson}$</p>
              <PersonIcon></PersonIcon> <PersonIcon></PersonIcon>{" "}
              <PersonIcon></PersonIcon>
              <p>Üç Kişilik Oda</p>
            </div>
            <div className="price-items">
              {" "}
              <p id="price-title">{currentProduct.fourPerson}$</p>
              <PersonIcon></PersonIcon> <PersonIcon></PersonIcon>{" "}
              <PersonIcon></PersonIcon>
              <PersonIcon></PersonIcon>
              <p>Dört Kişilik Oda</p>
            </div>
          </div>
          <div id="product-text">
            <div>Gidiş Tarihi</div>
            <div>
              {" "}
              <FlightTakeoffIcon></FlightTakeoffIcon>{" "}
            </div>
            <div> {currentProduct.gidis} </div>
            <div>
               Geçiş Tarihi:
            </div>{" "}
            <div>
              {" "}
              <DirectionsBusIcon></DirectionsBusIcon>{" "}
            </div>
            <div>{currentProduct.gecis}</div>
            <div>Dönüş Tarihi:</div>{" "}
            <div>
              {" "}
              <FlightLandIcon></FlightLandIcon>{" "}
            </div>
            <div>{currentProduct.donus}</div>
            <div>Mekke Otel:</div>{" "}
            <div>
              {" "}
              <HotelIcon></HotelIcon>{" "}
            </div>
            <div>{currentProduct.mekkeOtel}</div>
            <div>Medine Otel:</div>{" "}
            <div>
              {" "}
              <HotelIcon></HotelIcon>{" "}
            </div>
            <div>{currentProduct.medineOtel}</div>
          </div>
          <div id="product-prices">
            Not: Zorunlu hallerde, içerik aynı kalmak şartı ile programda
            değişiklik yapılabilir
          </div>
        </div>
      </div>

  {width >= 880 ? (    <div id="tab-context-cont">
        <TabContext value={value} >
          <TabList
            id="tab-list"
            variant="scrollable" scrollButtons="auto"
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            <Tab label="Detaylar" value="1" />
            <Tab label="Hediyeler" value="2" />
            <Tab label="Fiyata Dahil" value="3" />
            <Tab label="Fiyata Dahil Değil" value="4" />
            <Tab label="Gerekli Evraklar" value="5" />
            <Tab label="Banka Hesaplarımız" value="6" />
            <Tab label="Çocuk Fiyatları" value="7" />
          </TabList>{" "}
          <TabPanel id="tab-panel-cont" value="1">
            {" "}
            <ul>
              {detay.map((sentence, index) => (
                <li key={index}>{sentence}</li>
              ))}
            </ul>
          </TabPanel>
          <TabPanel id="tab-panel-cont" value="2">
            <ul>
              {hediye.map((sentence, index) => (
                <li key={index}>{sentence}</li>
              ))}
            </ul>
          </TabPanel>
          <TabPanel id="tab-panel-cont" value="3">
            <ul>
              {dahil.map((sentence, index) => (
                <li key={index}>{sentence} </li>
              ))}
            </ul>
          </TabPanel>
          <TabPanel id="tab-panel-cont" value="4">
            <ul>
              {dahildegil.map((sentence, index) => (
                <li key={index}>{sentence} </li>
              ))}
            </ul>
          </TabPanel>
          <TabPanel id="tab-panel-cont" value="5">
            <ul>
              {gerekli.map((sentence, index) => (
                <li key={index}>{sentence} </li>
              ))}
            </ul>
          </TabPanel>
          <TabPanel id="tab-panel-cont" value="6">
            <ul>
              {iptalsarti.map((sentence, index) => (
                <li key={index}>{sentence} </li>
              ))}
            </ul>
          </TabPanel>
          <TabPanel id="tab-panel-cont" value="7">
            <ul>
              {cocuk.map((sentence, index) => (
                <li key={index}>{sentence} </li>
              ))}
            </ul>
          </TabPanel>
        </TabContext>
      </div>) : (<div id="accordion-container" style={{marginTop:'4em'}} >  <Accordion style={{border:'1px solid gray'}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Detaylar
          </AccordionSummary>
          <AccordionDetails>
          <ul>
              {detay.map((sentence, index) => (
                <li key={index}>{sentence}</li>
              ))}
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{border:'1px solid gray'}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            Hediyeler
          </AccordionSummary>
          <AccordionDetails>
          <ul>
              {hediye.map((sentence, index) => (
                <li key={index}>{sentence}</li>
              ))}
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{border:'1px solid gray'}} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            Fiyata Dahil
          </AccordionSummary>
          <AccordionDetails>
          <ul>
              {dahil.map((sentence, index) => (
                <li key={index}>{sentence} </li>
              ))}
            </ul>
          </AccordionDetails>
       
        </Accordion>
        <Accordion style={{border:'1px solid gray'}} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            Fiyata Dahil Değil
          </AccordionSummary>
          <AccordionDetails>
          <ul>
              {dahildegil.map((sentence, index) => (
                <li key={index}>{sentence} </li>
              ))}
            </ul>
          </AccordionDetails>
       
        </Accordion>
        <Accordion style={{border:'1px solid gray'}} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            Gerekli Evraklar
          </AccordionSummary>
          <AccordionDetails>
          <ul>
              {gerekli.map((sentence, index) => (
                <li key={index}>{sentence} </li>
              ))}
            </ul>
          </AccordionDetails>
       
        </Accordion>
        <Accordion style={{border:'1px solid gray'}} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            Banka Hesaplarımız
          </AccordionSummary>
          <AccordionDetails>
          <ul>
              {iptalsarti.map((sentence, index) => (
                <li key={index}>{sentence} </li>
              ))}
            </ul>
          </AccordionDetails>
       
        </Accordion>
        <Accordion style={{border:'1px solid gray'}} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            Çocuk Fiyatları
          </AccordionSummary>
          <AccordionDetails>
          <ul>
              {cocuk.map((sentence, index) => (
                <li key={index}>{sentence} </li>
              ))}
            </ul>
          </AccordionDetails>
       
        </Accordion></div>)}
    </div>
  );
};

export default ProductPage;
