import React, { useState } from "react";
import "./Cultural.scss";
import c1 from '../../assets/culturalTours/culturalOne.jpeg'
import c2 from '../../assets/culturalTours/culturalTwo.jpeg'
import c3 from '../../assets/culturalTours/culturalThree.jpeg'
import c4 from '../../assets/culturalTours/culturalFour.jpeg'
import c5 from '../../assets/culturalTours/culturalFive.jpeg'
import c6 from '../../assets/culturalTours/culturalSix.jpeg'

const Cultural = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <div id="cultural-main">
      {selectedImage && (
        <div className="modal" onClick={handleClose}>
          <img className="modal-content" src={selectedImage} alt="Selected" />
        </div>
      )}
      <div id="cultural-cont">
        <img className="tour-items" alt="item1" src={c1} onClick={() => handleImageClick(c1)}></img>
        <img className="tour-items" alt="item1" src={c2} onClick={() => handleImageClick(c2)}></img>
        <img className="tour-items" alt="item1" src={c3} onClick={() => handleImageClick(c3)}></img>
        <img className="tour-items" alt="item1" src={c4} onClick={() => handleImageClick(c4)}></img>
        <img className="tour-items" alt="item1" src={c5} onClick={() => handleImageClick(c5)}></img>
        <img className="tour-items" alt="item1" src={c6} onClick={() => handleImageClick(c6)}></img>
      </div>
    </div>
  );
};

export default Cultural;
