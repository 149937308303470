import React, { useState } from "react";
import "./Card.scss";
import LinearProgress from "@mui/material/LinearProgress";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import CallIcon from "@mui/icons-material/Call";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import logo from "../../assets/evliyalogo.png";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import { Link } from "react-router-dom";

const Card = (props) => {
  const [seatCount, setSeatCount] = useState(
    Math.floor(Math.random() * (20 - 8 + 1) + 8)
  );
  return (
    <div className="card-container">
      <img alt="card-img" id="card-img" src={`/login/${props.tur.img}`} />
      <div id="card-text-cont">
        <div id="card-title">{props.tur.tur_ismi}</div>
        <div id="card-dates">
          <div>
            <p>{props.tur.gidis}</p>
            <p>{props.tur.gecis}</p>
            <p>{props.tur.donus}</p>
          </div>
          <div>
            <p>
              {props.tur.kalkisyeri} <FlightTakeoffIcon fontSize="small" />{" "}
              {props.tur.gecisyeri}
            </p>
            <p>
              {props.tur.gecisyeri} <DirectionsBusIcon fontSize="small" />{" "}
              {props.tur.donusyeri}
            </p>
            <p>
              {props.tur.donusyeri} <FlightLandIcon fontSize="small" />{" "}
              {props.tur.kalkisyeri}
            </p>
          </div>
          <img id="card-logo" alt="card-logo" src={logo}></img>
        </div>
        <div id="card-graph">
          <LinearProgress
            style={{ height: "2em", alignSelf: "left", width: "100%" }}
            color="warning"
            variant="determinate"
            value={60}
          />

          <div id="seats">SON {seatCount} KOLTUK!</div>
        </div>
        <div id="card-prices">
          <div id="low-price">
            {" "}
            <span
              style={{ color: "green", fontWeight: "bold", fontSize: "25px" }}
            >
              {props.tur.fourPerson}
            </span>{" "}
            $'dan başlayan fiyatlar ile
          </div>
          <Link to={`/product-detail/${props.tur.id}`} id="detail-buton">
            DETAYLAR
          </Link>

          <a href="tel:03123109191" id="call-buton">
            <CallIcon /> HEMEN ARA
          </a>
        </div>
      </div>
    </div>
  );
};

export default Card;
